@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');

@font-face {
    font-family: 'Moderustic';
    src: url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap/VerbMedium-Italic.woff2');
  }
  
  .container {
    position: relative;
    background-color: #f2f3f4;
    border-radius: 4px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    width: 400px;
    max-width: 100%;
    min-height: 540px;
    padding-top: 140px; 
}

.linkButton {
    width: 360px;
    height: 40px;
    margin: 4px;
    border: solid;
    border-width: 2px;
    border-radius: 4px;
    border-color: #AC97E3;
    /* margin-left: 20px; */
    margin-top: 7px;
    margin-bottom: 7px;
}

.linkButton:hover {
    cursor: pointer;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.05), 0 5px 8px 0 rgba(0,0,0,0.05);
    border-color: #6447F2;
}

.icon {
    width: 25px;
    margin: 5px;
    margin-top: 6px;
}

.pandaImage {
    background-color: #f2f3f4;
    position: absolute;
    width: 220px;
    border-radius: 50%;
    z-index: 2;
    padding: 8px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
    margin-left: -107px;
    margin-top: -110px;
}

h3 {
    font-family: user, Helvetica, sans-serif;
    font-size: 13px;
    text-decoration: none;
    margin-left: 10px;
    margin-top: 11px;
}

.text-box {
    padding-bottom: 60px;
}

.flex-box {
    display: flex;
}
