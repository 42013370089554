.header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    justify-content: flex-end;
}
.usericon {
    color: #fff;
}
.user-name {
    color: #fff;
    font-weight: bolder;
    font-size: 16px;
    padding-bottom: 17px;
}
.signout-button {
    color: #fff;
    background-color: #AC97E3;
    border: none;
    margin-right: 17px;

}

.signout-container {
    display: flex;
    background-color: #AC97E3;
    width: fit-content;
    border-radius:360px;
    border: none;
    color: white;
    padding: 5px 32px;
    position: absolute;
    white-space: nowrap;
    padding: 5px;
  }
.sign-out-left {
    display: flex;
    flex-direction: column;
    margin-right: -10px;
    float: left;
    margin-top: -9px;
}
.sign-out-right {
    display: flex;
    justify-content: end;
    margin-top: 1px;
    font-size: 19px;
}
.button1 {
    display: flex;
    margin-top: -15px;
    margin-left: 40px;
    justify-content: flex-end;
}
.signout-button:hover {
    cursor: pointer;
    background-color: white;
    color: #AC97E3;
    border-color: #6447F2;
    border-radius: 10px;;
    width: 64px;
    height: 16px;
    font-weight: bold;
}

