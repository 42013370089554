@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');

@font-face {
    font-family: 'user';
    src: url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap/VerbMedium-Italic.woff2');
  }


div[data-lastpass-icon-root] {
    display: none !important;
}

body {
    background: url("/Users/jim.sweeney/Protenus_Workspace/repos/ssohub/ProtenusHub/public/background.png");
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    position: center;
   
}
h2 {
    color: #6447F2;
    font-family: user, Helvetica, sans-serif;
    font-size: 19px;
    padding: 16px;
    font-weight: bolder;
  }

h3 {
    font-family: user, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: lighter;
}

.container {
    position: relative;
    background-color: #f2f3f4;
    border-radius: 4px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    width: 400px;
    max-width: 100%;
    min-height: 540px;
    padding-top: 140px;
}

.login {
    left: 0;
    border-radius: 7px;
    height: 20px;
    padding-top: 20px;
}

.button {
    width: 320px;
    height: 52px;
    background: #6447F2;
    border: none;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-weight: lighter;
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 25px;
}

.pandaImage {
    background-color: #f2f3f4;
    position: absolute;
    width: 220px;
    border-radius: 50%;
    z-index: 2;
    padding: 8px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
    margin-left: -107px;
    margin-top: -110px;
}

.logoImage {
    max-width: 290px;
    margin-left: 10px;
}